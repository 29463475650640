import * as React from "react"

function USDDSvg(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1600 1666"
      {...props}
    >
      <defs>
        <style>{".cls-2{fill:#fff}"}</style>
      </defs>
      <circle cx={782.51} cy={833.67} r={748.5} fill="#216c58" />
      <path
        className="cls-2"
        d="M1447.55 867.67a14 14 0 00-14 13c-24.28 339.68-310.26 605.76-651.08 605.76s-626.76-266.09-651.04-605.77a14 14 0 00-27.95 2 681 681 0 001306.27 216 675.55 675.55 0 0051.78-216 14 14 0 00-14-15zM117.46 799.67a14.05 14.05 0 0014-13C155.71 447 441.69 180.92 782.5 180.92S1409.3 447 1433.58 786.67a14 14 0 0028-2 681 681 0 00-1306.27-216 675.5 675.5 0 00-51.78 216 14 14 0 0014 15z"
      />
      <path
        className="cls-2"
        d="M879.88 821.68c-19.09-5.52-39.17-10.61-60.08-15.44q-14.09-3.25-28.66-6.38c-2.79-.58-5.51-1.17-8.16-1.75-25.33-5.56-45-11.06-60.09-17-33-13-44-28.1-44-50.35 0-23.08 17.66-40 44-50.23 17.16-6.64 38-10.42 60.09-11.16 2.71-.1 5.43-.14 8.16-.14 9.93 0 19.48.47 28.66 1.35a285 285 0 0160.08 12.3c66.16 21.23 101.28 59.76 101.28 59.76l75.57-136c-53.85-49.61-122.61-72.78-176.85-83.55a500.2 500.2 0 00-60.08-8.2c-12.59-.93-21.84-1.1-26.49-1.1q-5.2 0-10.33.13a397 397 0 00-60.09 5.82C585.53 544 506.13 639.33 506.13 749.12c0 109.52 69.37 176.1 216.76 213.47Q751 969.73 783 975.47c2.7.5 5.42 1 8.16 1.45 10.36 1.76 19.89 3.59 28.66 5.49 26.5 5.75 46 12.17 60.08 19.55 24 12.57 32.18 27.89 32.18 47.29 0 21-9.3 39.11-32.18 51.48-14.33 7.75-34 13.27-60.08 15.89-11.09 1.12-23.34 1.71-36.82 1.72h-.47a252.39 252.39 0 01-59.62-7.45C672.63 1098.66 615 1070 559 1014.7l-78.82 138.18s92 95.39 242.67 116.72a424.23 424.23 0 0059.62 4.2h.53q19 0 36.82-1.28a441 441 0 0060.08-8.22c133.84-28.16 204.91-116.13 204.91-225.85-.02-126.9-82.01-181.15-204.93-216.77z"
      />
      <rect
        className="cls-2"
        x={550.39}
        y={358}
        width={522.34}
        height={61.05}
        rx={30.53}
      />
      <path
        className="cls-2"
        d="M783 447.16v57a408.17 408.17 0 00-60.09 5.64v-62.64zM722.89 519.71a397 397 0 0160.11-5.82v274.29c-26.67-5.93-46.09-11.64-60.09-17.52zM783 798.11v310.53h-.47a246 246 0 01-59.62-7.67V781.11c15.09 5.94 34.74 11.44 60.09 17zM782.51 1118.34h.49v192.28a30 30 0 11-60.09 0v-199.73a252.39 252.39 0 0059.6 7.45zM879.88 447.16v66a505.72 505.72 0 00-60.08-8v-58zM819.8 514.86a500.2 500.2 0 0160.08 8.2v288.51c-19.67-5.64-39.83-10.65-60.08-15.29zM879.88 821.68v267.72q-20.64 13.42-60.08 17.49V806.24c20.91 4.83 40.99 9.92 60.08 15.44zM819.8 1116.62c26.07-2.62 45.75-8.14 60.08-15.89v209.89a30 30 0 11-60.08 0z"
      />
    </svg>
  )
}

export default USDDSvg
